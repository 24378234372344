import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import classNames from 'classnames'
import { send as sendEmail } from 'emailjs-com'
import { graphql, useStaticQuery } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { isSafari } from 'react-device-detect'
import { useForm } from 'react-hook-form'
import {
  Alert,
  Button,
  FlexboxCol,
  FlexboxRow,
  Input,
  Loader,
  Text,
  Wysiwyg,
} from 'src/components/Base'
import * as yup from 'yup'

import {
  cAlertAnimation,
  cCheckbox,
  cCheckboxSafari,
  cDsgvo,
  cDsgvoErrorMessage,
  cDsgvoText,
  cFlexCol,
  cFlexRow,
  cForm,
  cFormHide,
  cFormRow,
  cFormWrapper,
  cLoader,
  cSubmit,
} from './styles.module.scss'

const EventSignupForm = ({ toEmail, eventTitle, eventDate, eventId }) => {
  const [formData] = useState({})
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState()

  const staticData = useStaticQuery(graphql`
    query eventSignupDsgvoTextQuery {
      allWp {
        nodes {
          themeGeneralSettings {
            atspEventSignupFormOptions {
              eventSingnupFormTexts {
                dsgvo
                emailSendError
                emailSendSuccessfully
                fieldGroupName
                validationCity
                validationDsgvo
                validationFirstname
                validationEmail
                validationLastname
                validationPhone
                validationRequired
                validationZip
              }
              eventSignupFormLabels {
                city
                company
                email
                fieldGroupName
                firstname
                lastname
                phone
                position
                street
                submit
                zip
              }
            }
          }
        }
      }
    }
  `)

  const translationOptions =
    staticData.allWp.nodes[0].themeGeneralSettings.atspEventSignupFormOptions
  const labelTranslations = translationOptions.eventSignupFormLabels
  const formTexts = translationOptions.eventSingnupFormTexts

  const schema = yup.object().shape({
    firstname: yup.string().required(formTexts.validationRequired),
    lastname: yup.string().required(formTexts.validationRequired),
    company: yup.string().required(formTexts.validationRequired),
    position: yup.string().required(formTexts.validationRequired),
    street: yup.string().required(formTexts.validationRequired),
    zip: yup
      .string()
      .required(formTexts.validationZip)
      .min(4, formTexts.validationZip)
      .max(5, formTexts.validationZip)
      .matches('^[0-9]*$', formTexts.validationZip),
    city: yup.string().required(formTexts.validationRequired),
    email: yup
      .string()
      .matches(
        /^(([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,12})(\]?)(\s*(;|,)\s*|\s*$))*$/,
        formTexts.validationEmail
      )
      .required(formTexts.validationRequired),
    phone: yup
      .string()
      .required(formTexts.validationRequired)
      .matches(/^[0-9*#+() ]+$/, formTexts.validationPhone),
    dsgvo: yup.bool().oneOf([true], formTexts.validationDsgvo),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: formData.firstname,
      lastname: formData.lastname,
      dsgvo: formData.dsgvo,
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = (data) => {
    let emailJsData = {
      ...data,
      toEmail: toEmail,
      eventTitle: eventTitle,
      eventDate: eventDate,
    }

    setLoading(true)

    sendEmail(
      process.env.GATSBY_EMAILJS_SERVICE_ID,
      process.env.GATSBY_EMAILJS_TEMPLATE_ID,
      emailJsData,
      process.env.GATSBY_EMAILJS_USER_ID
    ).then(
      (response) => {
        onFormProceed(true, data)
      },
      (err) => {
        console.log(err)
        onFormProceed(false, data)
      }
    )
  }

  const onFormProceed = (success, data) => {
    setLoading(false)
    setDone(true)
    setAlertType(success ? 'success' : 'error')
    setAlertMessage(
      success ? formTexts.emailSendSuccessfully : formTexts.emailSendError
    )
    scrollTo('#signup')

    if (success) {
      var params = new URLSearchParams()

      params.append('action', 'insert_event_participant')
      params.append('event_id', eventId)
      params.append('event_title', eventTitle)
      params.append('event_date', eventDate)
      params.append('participant_firstname', data.firstname)
      params.append('participant_lastname', data.lastname)
      params.append('participant_company', data.company)
      params.append('participant_position', data.position)
      params.append('participant_street', data.street)
      params.append('participant_zip', data.zip)
      params.append('participant_city', data.city)
      params.append('participant_email', data.email)
      params.append('participant_phone', data.phone)

      axios
        .post(process.env.GATSBY_WORDPRESS_AJAX_URL, params)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          //  alert('error')
          console.log('error', error)
        })
        .then(() => {
          // finally
          console.log('finally')
        })
    }
  }

  const formClassses = classNames({
    [cForm]: true,
    [cFormHide]: done,
  })

  const checkBoxClasses = classNames({
    [cCheckbox]: true,
    [cCheckboxSafari]: isSafari,
  })

  return (
    <>
      <div className={cFormWrapper} id="signup">
        {done && (
          <Alert className={cAlertAnimation} color={alertType}>
            {alertMessage}
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className={formClassses}>
          <FlexboxRow className={classNames(cFormRow, cFlexRow)}>
            <FlexboxCol className={cFlexCol} width={6}>
              <Input
                type="text"
                name="firstname"
                value={formData.firstname}
                label={labelTranslations.firstname}
                {...register('firstname')}
                disabled={loading}
                error={errors.firstname ? errors.firstname.message : null}
              />
            </FlexboxCol>
            <FlexboxCol className={cFlexCol} width={6}>
              <Input
                type="text"
                name="lastname"
                value={formData.lastname}
                label={labelTranslations.lastname}
                {...register('lastname')}
                disabled={loading}
                error={errors.lastname ? errors.lastname.message : null}
              />
            </FlexboxCol>
          </FlexboxRow>
          <div className={cFormRow}>
            <Input
              type="text"
              name="company"
              value={formData.company}
              label={labelTranslations.company}
              {...register('company')}
              disabled={loading}
              error={errors.company ? errors.company.message : null}
            />
          </div>
          <div className={cFormRow}>
            <Input
              type="text"
              name="position"
              value={formData.position}
              label={labelTranslations.position}
              {...register('position')}
              disabled={loading}
              error={errors.position ? errors.position.message : null}
            />
          </div>
          <div className={cFormRow}>
            <Input
              type="text"
              name="street"
              value={formData.street}
              label={labelTranslations.street}
              {...register('street')}
              disabled={loading}
              error={errors.street ? errors.street.message : null}
            />
          </div>
          <FlexboxRow className={classNames(cFormRow, cFlexRow)}>
            <FlexboxCol className={cFlexCol} width={4}>
              <Input
                type="text"
                name="zip"
                value={formData.zip}
                label={labelTranslations.zip}
                {...register('zip')}
                disabled={loading}
                error={errors.zip ? errors.zip.message : null}
              />
            </FlexboxCol>
            <FlexboxCol className={cFlexCol} width={8}>
              <Input
                type="text"
                name="city"
                value={formData.city}
                label={labelTranslations.city}
                {...register('city')}
                disabled={loading}
                error={errors.city ? errors.city.message : null}
              />
            </FlexboxCol>
          </FlexboxRow>
          <div className={cFormRow}>
            <Input
              type="text"
              name="email"
              value={formData.email}
              label={labelTranslations.email}
              {...register('email')}
              disabled={loading}
              error={errors.email ? errors.email.message : null}
            />
          </div>
          <div className={cFormRow}>
            <Input
              type="text"
              name="phone"
              value={formData.phone}
              label={labelTranslations.phone}
              {...register('phone')}
              disabled={loading}
              error={errors.phone ? errors.phone.message : null}
            />
          </div>
          <div className={cDsgvo}>
            <input
              style={{ display: 'initial' }}
              disabled={loading}
              type="checkbox"
              name="dsgvo"
              className={checkBoxClasses}
              title="dsgvo-checkbox"
              {...register('dsgvo')}
              checked={formData.dsgvo}
            />
            <Wysiwyg className={cDsgvoText}>{formTexts.dsgvo}</Wysiwyg>
          </div>
          {errors.dsgvo && (
            <Text className={cDsgvoErrorMessage}>{errors.dsgvo.message}</Text>
          )}
          <Button
            disabled={loading}
            className={cSubmit}
            color="cta"
            type="submit"
            label={labelTranslations.submit + ' Button'}
          >
            {loading && <Loader className={cLoader} />}{' '}
            {labelTranslations.submit}
          </Button>
        </form>
      </div>
    </>
  )
}

EventSignupForm.propTypes = {
  toEmail: PropTypes.string.isRequired,
  eventTitle: PropTypes.string.isRequired,
  eventDate: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
}

export default EventSignupForm
